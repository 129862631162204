export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пулов"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алгоритм"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмиссия за последние 24 часа"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хешрейт пулов"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хешрейт сети"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объем за 24 часа"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рыночная капитализация"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний блок (высота)"])}
      },
      "en": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pools"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithm"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissions in the last 24 hours"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool hashrate"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network hashrate"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume in 24 hours"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market capitalization"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last block (height)"])}
      },
      "de": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergbau-Pools"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithmus"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissionen in den letzten 24 Stunden"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool-Hashrate"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk-Hashrate"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen in 24 Stunden"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Block (Höhe)"])}
      },
      "es": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscinas"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmo"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisiones en las últimas 24 horas"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de hash de la piscina"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de hash de red"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen en 24 horas"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalización de mercado"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último bloque (altura)"])}
      },
      "pt": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscinas"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmo"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissões nas últimas 24 horas"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de hash do pool"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de hash da rede"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume em 24 horas"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalização de mercado"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último bloco (altura)"])}
      },
      "fr": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscines"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithme"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émissions au cours des dernières 24 heures"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de hachage du pool"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de hachage du réseau"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume en 24 heures"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalisation boursière"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier bloc (hauteur)"])}
      },
      "it": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscine"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmo"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissioni nelle ultime 24 ore"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashrate in piscina"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashrate di rete"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume in 24 ore"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalizzazione di mercato"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo blocco (altezza)"])}
      },
      "pl": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalizator piłkarski"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorytm"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisje w ciągu ostatnich 24 godzin"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashrate w basenie"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashrate sieci"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Głośność w ciągu 24 godzin"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitalizacja rynkowa"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni blok (wysokość)"])}
      },
      "zh": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游泳池"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["算法"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["價錢"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去 24 小時內的排放量"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礦池算力"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網絡算力"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 小時內的交易量"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市值"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後一塊（高度）"])}
      },
      "ja": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プール"])},
        "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルゴリズム"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
        "emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去24時間の排出量"])},
        "pools_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プールハッシュレート"])},
        "network_hashrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネットワークハッシュレート"])},
        "volume_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間でボリューム"])},
        "market_cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時価総額"])},
        "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後のブロック（高さ）"])}
      }
    }
  })
}
