export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майнинг пулы"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше монет"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining pools"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More mining pools"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining-Pools"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Mining-Pools"])}
      },
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscinas mineras"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más pools de minería"])}
      },
      "pt": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscinas de mineração"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais pools de mineração"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscines minières"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de pools de minageo"])}
      },
      "it": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscine minerarie"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più piscine minerarie"])}
      },
      "pl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baseny górnicze"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej basenów górniczych"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礦池"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多礦池"])}
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイニングプール"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より多くのマイニングプール"])}
      }
    }
  })
}
