<template lang='pug'>
header.header(:class="{'header_dark': isErrorPage}")
  .header__cont.cont
    .header__panel
      .header__burger(@click="toggleMobileMenu", :class="{'header__burger_close' : isOpenMobileMenu }")
        .header__burger-line
      span.header__logo(v-if="$route.name === 'Home'")
        img.header__logo-image(src="/images/logo.svg", alt="Логотип Cryptopack")
      router-link.header__logo(v-else-if="activeLang.locale === 'en'", :to="{ name: 'Home' }")
        img.header__logo-image(src="/images/logo.svg", alt="Логотип Cryptopack")
      router-link.header__logo(v-else, :to="{ name: 'Home', params: {locale: `${activeLang.locale}`} }")
        img.header__logo-image(src="/images/logo.svg", alt="Логотип Cryptopack")
      .header__lang-list(:class="{'header__lang-list_open': isShowLangList}", @mouseover="showLangList", @mouseleave="hideLangList")
        span.header__lang-item(v-if="activeLang.locale === $i18n.locale")
          img.header__lang-image(:src="`/images/ic_flag_${activeLang.locale}.svg`", alt="", loading="lazy")
          span.header__lang {{ activeLang.text }}
        template(v-for="(lang, i) in langList", :key="i")
          router-link.header__lang-item(v-if="lang.locale === 'en'", :to="{ name: $route.name, query: { page: $route.query.page }}")
            img.header__lang-image(:src="`/images/ic_flag_${lang.locale}.svg`", alt="", loading="lazy")
            span.header__lang {{ lang.text }}
          router-link.header__lang-item(v-else, :to="{ name: $route.name, params: { locale: lang.locale }, query: { page: $route.query.page }}")
            img.header__lang-image(:src="`/images/ic_flag_${lang.locale}.svg`", alt="", loading="lazy")
            span.header__lang {{ lang.text }}
    nav.header__nav(:class="{'header__nav_show': isOpenMobileMenu }")
      .header__nav-cont
        template(v-for="(link, i) in links", :key="i")
          span.header__nav-link.text.link(v-if="$route.name === link.path") {{ t(link.name) }}
          router-link.header__nav-link.text.link(
              v-else-if="activeLang.locale === 'en'"
              :to="{ name: link.path }",
              @click="closeMobileMenu",
            ) {{ t(link.name) }}
          router-link.header__nav-link.text.link(
              v-else,
              :to="{ name: link.path, params: { locale: `${activeLang.locale}`} }",
              @click="closeMobileMenu",
            ) {{ t(link.name) }}
  .header__layer(v-if="isOpenMobileMenu", @click="closeMobileMenu")
</template>

<script>
import '@/assets/styles/components/header.sass'
import { useI18n } from 'vue-i18n'

export default {
  name: 'TheHeader',
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  data () {
    return {
      links: [
        { path: 'Coins', name: 'coins' },
        { path: 'Pools', name: 'pools' },
        { path: 'Algorithms', name: 'algorithms' }
      ],
      activeLang: { text: 'EN', locale: 'en' },
      languages: [
        { text: 'RU', locale: 'ru' },
        { text: 'ES', locale: 'es' },
        { text: 'PT', locale: 'pt' },
        { text: 'DE', locale: 'de' },
        { text: 'FR', locale: 'fr' },
        { text: 'IT', locale: 'it' },
        { text: 'EN', locale: 'en' },
        { text: 'PL', locale: 'pl' },
        { text: '中文', locale: 'zh' },
        { text: '日本', locale: 'ja' }
      ],
      isHomePage: true,
      isErrorPage: false,
      isOpenMobileMenu: false,
      isShowLangList: false
    }
  },
  methods: {
    closeMobileMenu () {
      this.isOpenMobileMenu = false
    },
    toggleMobileMenu () {
      this.isOpenMobileMenu = !this.isOpenMobileMenu
    },
    showLangList () {
      this.isShowLangList = true
    },
    hideLangList () {
      this.isShowLangList = false
    }
  },
  computed: {
    langList () {
      return this.languages.filter(item => {
        if (item.locale !== this.$i18n.locale) return item
        this.activeLang = item
      })
    },
    currentPath () {
      return this.$route.name
    }
  },
  watch: {
    '$route.name' () {
      this.isHomePage = this.$route.name === 'Home'
      this.isErrorPage = this.$route.name === 'Error'
    },
    '$route.params.locale' () {
      this.hideLangList()
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "coins": "Монеты",
    "pools": "Майнинг пулы",
    "algorithms": "Алгоритмы"
  },
  "en": {
    "coins": "Cоins",
    "pools": "Mining pools",
    "algorithms": "Algorithms"
  },
  "de": {
    "coins": "Münzen",
    "pools": "Münzen",
    "algorithms": "Algorithmen"
  },
  "es": {
    "coins": "Monedas",
    "pools": "Pools mineros",
    "algorithms": "Algoritmos"
  },
  "pt": {
    "coins": "Moedas",
    "pools": "Piscinas mineiras",
    "algorithms": "Algoritmos"
  },
  "fr": {
    "coins": "Pièces de monnaie",
    "pools": "Pools miniers",
    "algorithms": "Algorithmes"
  },
  "it": {
    "coins": "Monete",
    "pools": "Pool di estrazione mineraria",
    "algorithms": "Algoritmi"
  },
  "pl": {
    "coins": "Monety",
    "pools": "Pule wydobywcze",
    "algorithms": "Algorytmy"
  },
  "zh": {
    "coins": "硬幣",
    "pools": "礦池e",
    "algorithms": "算法"
  },
  "ja": {
    "coins": "コイン",
    "pools": "マイニングプール",
    "algorithms": "アルゴリズム"
  }
}
</i18n>
