<template lang="pug">
coins-info(v-if="coinsInfo", :info="coinsInfo")
pools-info(v-if="poolsInfo", :info="poolsInfo")
</template>

<script>
import CoinsInfo from '../components/tables/CoinsInfo'
import PoolsInfo from '../components/tables/PoolsInfo'
import { getCoinsForTheHomepage, getPoolsForTheHomepage } from '../assets/scripts/API'
import { addMeta } from '../assets/scripts/add-meta'
import { useI18n } from 'vue-i18n'

export default {
  name: 'HomePage',
  components: { PoolsInfo, CoinsInfo },
  data () {
    return {
      coinsInfo: null,
      poolsInfo: null
    }
  },
  mounted () {
    getCoinsForTheHomepage().then(data => {
      this.coinsInfo = data
    })
    getPoolsForTheHomepage().then(data => {
      this.poolsInfo = data
    })
    addMeta(this.t('title'), this.t('description'))
  },
  watch: {
    '$i18n.locale' () {
      addMeta(this.t('title'), this.t('description'))
    }
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
      messages: {
        ru: {
          title: 'Cryptopack — подбор монет, майнинг пулов, алгоритмов их сравнение и статистика',
          description: 'Поиск монет и пулов для майнинга, сравнение параметров, комиссий, отзывы пользователей'
        },
        en: {
          title: 'Cryptopack - coin selection, mining pools, algorithm comparison and statistics',
          description: 'Search for coins and pools for mining, comparison of parameters, commissions, user reviews'
        },
        es: {
          title: 'Cryptopack - selección de monedas, pools de minería, comparación de algoritmos y estadísticas',
          description: 'Búsqueda de monedas y pools para minería, comparación de parámetros, comisiones, reseñas de usuarios'
        },
        pt: {
          title: 'Cryptopack - seleção de moedas, pools de mineração, comparação de algoritmos e estatísticas',
          description: 'Pesquise moedas e pools para mineração, comparação de parâmetros, comissões, análises de usuários'
        },
        de: {
          title: 'Cryptopack - Münzauswahl, Mining-Pools, Algorithmusvergleich und Statistiken',
          description: 'Suche nach Münzen und Pools für das Mining, Vergleich von Parametern, Provisionen, Benutzerbewertungen'
        },
        fr: {
          title: 'Cryptopack - sélection de pièces, pools de minage, comparaison d\'algorithmes et statistiques',
          description: 'Recherche de pièces et de pools pour l\'exploitation minière, comparaison de paramètres, commissions, avis d\'utilisateurs'
        },
        it: {
          title: 'Cryptopack - selezione di monete, pool di mining, confronto di algoritmi e statistiche',
          description: 'Cerca monete e pool per il mining, confronto di parametri, commissioni, recensioni degli utenti'
        },
        pl: {
          title: 'Cryptopack - wybór monet, pule wydobywcze, porównanie algorytmów i statystyki',
          description: 'Wyszukiwanie monet i puli do wydobycia, porównywanie parametrów, prowizje, recenzje użytkowników'
        },
        zh: {
          title: 'Cryptopack - 硬幣選擇、礦池、算法比較和統計',
          description: '搜索幣和礦池進行挖礦、參數比較、佣金、用戶評論'
        },
        ja: {
          title: 'Cryptopack-コインの選択、マイニングプール、アルゴリズムの比較と統計',
          description: 'マイニング、パラメーターの比較、手数料、ユーザーレビューのためのコインとプールを検索します'
        }
      }
    })
    return { t }
  }
}
</script>
