export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монеты"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майнинг пулы"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алгоритмы"])}
      },
      "en": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cоins"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining pools"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithms"])}
      },
      "de": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Münzen"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Münzen"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithmen"])}
      },
      "es": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monedas"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pools mineros"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmos"])}
      },
      "pt": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moedas"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscinas mineiras"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmos"])}
      },
      "fr": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces de monnaie"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pools miniers"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithmes"])}
      },
      "it": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monete"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool di estrazione mineraria"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmi"])}
      },
      "pl": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monety"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pule wydobywcze"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorytmy"])}
      },
      "zh": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬幣"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礦池e"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["算法"])}
      },
      "ja": {
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コイン"])},
        "pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイニングプール"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルゴリズム"])}
      }
    }
  })
}
