<template lang='pug'>
footer.footer
  .footer__cont.cont
    span.footer__logo(v-if="$route.name === 'Home'")
      img.footer__logo-image(src="/images/logo.svg", alt="Логотип Cryptopack")
    router-link.footer__logo(v-else-if="$i18n.locale ==='en'", :to="{ name: 'Home' }")
      img.footer__logo-image(src="/images/logo.svg", alt="Логотип Cryptopack")
    router-link.footer__logo(v-else, :to="{ name: 'Home', params: { locale: $i18n.locale }}")
      img.footer__logo-image(src="/images/logo.svg", alt="Логотип Cryptopack")
    .footer__wrapper
      ul.footer__lang-list
        li.footer__lang-item(v-for="(lang, i) in languages", :key="i")
            span.footer__lang-text.text(v-if="lang.locale === $i18n.locale") {{ lang.text }}
            router-link.footer__lang-text.link-white.text(
                v-else-if="lang.locale === 'en'",
                :to="{ name: $route.name, query: { page: $route.query.page }}"
              ) {{ lang.text }}
            router-link.footer__lang-text.link-white.text(
                v-else,
                :to="{ name: $route.name, params: { locale: lang.locale }, query: { page: $route.query.page }}",
              ) {{ lang.text }}
      //ul.footer__menu-list
      //  li.footer__menu-item(v-for="(page, i) in menuLinks", :key="i")
      //    router-link.footer__menu-text.link-white.text-small(:to="{ name: page.pathName}") {{t(page.translation)}}
</template>

<script>
import '@/assets/styles/components/footer.sass'
import { useI18n } from 'vue-i18n'

export default {
  name: 'TheFooter',
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  data () {
    return {
      languages: [
        { text: 'Русский', locale: 'ru' },
        { text: 'English', locale: 'en' },
        { text: 'Español', locale: 'es' },
        { text: 'Português', locale: 'pt' },
        { text: 'Deutsch', locale: 'de' },
        { text: 'Français', locale: 'fr' },
        { text: 'Italianol', locale: 'it' },
        { text: 'Polski', locale: 'pl' },
        { text: '中文', locale: 'zh' },
        { text: '日本語', locale: 'ja' }
      ],
      menuLinks: [
        { pathName: 'About', translation: 'about' },
        { pathName: 'Rules', translation: 'rules' },
        { pathName: 'Policy', translation: 'policy' }
      ]
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "about": "О сайте",
    "rules": "Правила сервиса",
    "policy": "Политика конфиденциальности"
  },
  "en": {
    "about": "About site",
    "rules": "Terms of service",
    "policy": "Privacy policy"
  },
  "de": {
    "about": "Über die Website",
    "rules": "Bedingungen der Dienstleistung",
    "policy": "Datenschutzbestimmungen"
  },
  "es": {
    "about": "Sobre el sitio",
    "rules": "Reglas de servicio",
    "policy": "Política de privacidad"
  },
  "pt": {
    "about": "Sobre o site",
    "rules": "Regras de serviço",
    "policy": "Política de Privacidade"
  },
  "fr": {
    "about": "À propos du site",
    "rules": "Règles de services",
    "policy": "Politique de confidentialité"
  },
  "it": {
    "about": "Informazioni sul sito",
    "rules": "Regole del servizio",
    "policy": "Politica sulla riservatezza"
  },
  "pl": {
    "about": "O stronie",
    "rules": "Zasady obsługi",
    "policy": "Polityka prywatności"
  },
  "zh": {
    "about": "關於網站",
    "rules": "服務規則",
    "policy": "隱私政策"
  },
  "ja": {
    "about": "サイトについて",
    "rules": "サービスルール",
    "policy": "プライバシーポリシー"
  }
}
</i18n>
