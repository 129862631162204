<template lang="pug">
table.data-table.data-table_pools(v-if="pools && pools.length")
  thead.data-table__thead
    tr.data-table__row
      th.data-table__head
      //-template(v-for="(header, i) in headers", :key="i")
        th.data-table__head(v-if="header.sort")
          button.data-table__head-sort {{header.text}}
      th.data-table__head(v-for="(header, i) in headers", :key="i") {{ t(header.translation) }}
  tbody.data-table__body
    tr.data-table__row.data-table__row_hover(v-for="(item, i) in pools", :key="i", @click="goToPoolPage(item.slug)")
      td.data-table__cell.data-table__cell_number {{i + 1}}.
      td.data-table__cell.data-table__cell_pool-name
        .data-table__link
          img.data-table__icon(v-if="item.country_image", :src="item.country_image", alt="" )
          span.data-table__text {{ item.name }}
      td.data-table__cell.data-table__cell_comission {{ item.commission }} %
      td.data-table__cell.data-table__cell_algorithms {{ item.count_algo }}
      td.data-table__cell.data-table__cell_coins {{ item.count_coins }}
      td.data-table__cell {{item.lastblock}}
      td.data-table__cell {{item.lastblockеtime}}
</template>

<script>
import '@/assets/styles/components/data-table.sass'
import { useI18n } from 'vue-i18n'

export default {
  name: 'PoolsTable',
  props: {
    pools: Array
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  data () {
    return {
      headers: [
        { translation: 'name', sort: true },
        { translation: 'commission', sort: true },
        { translation: 'algorithms', sort: true },
        { translation: 'coins', sort: true },
        { translation: 'lastblock', sort: true },
        { translation: 'lastblockеtime', sort: true }
      ]
    }
  },
  methods: {
    goToPoolPage (slug) {
      if (this.$i18n.locale === 'en') {
        this.$router.push({ name: 'Pool', params: { slug } })
      } else {
        this.$router.push({ name: 'Pool', params: { slug, locale: this.$i18n.locale } })
      }
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "name": "Название",
    "commission": "Комиссия",
    "algorithms": "Алгоритмов",
    "coins": "Монет",
    "lastblock": "Последний блок",
    "lastblockеtime": "Время последнего блока"
  },
  "en": {
    "name": "Name",
    "commission": "Commission",
    "algorithms": "Algorithms",
    "coins": "Сoins",
    "lastblock": "Last block",
    "lastblockеtime": "Last block time"
  },
  "de": {
    "name": "Name",
    "commission": "Kommission",
    "algorithms": "Algorithmen",
    "coins": "Münzen",
    "lastblock": "Letzter Block",
    "lastblockеtime": "Letzte Blockzeit"
  },
  "es": {
    "name": "Nombre",
    "commission": "Comisión",
    "algorithms": "Algoritmos",
    "coins": "Monedas",
    "lastblock": "último bloque",
    "lastblockеtime": "Hora del último bloque"
  },
  "pt": {
    "name": "Nome",
    "commission": "Comissão",
    "algorithms": "Algoritmos",
    "coins": "Moedas",
    "lastblock": "Último bloco",
    "lastblockеtime": "Hora do último bloco"
  },
  "fr": {
    "name": "Nom",
    "commission": "Commission",
    "algorithms": "Algorithmes",
    "coins": "Pièces de monnaie",
    "lastblock": "Dernier bloc",
    "lastblockеtime": "Heure du dernier bloc"
  },
  "it": {
    "name": "Nome",
    "commission": "Commissione",
    "algorithms": "Algoritmi",
    "coins": "Monete",
    "lastblock": "Ultimo blocco",
    "lastblockеtime": "Tempo dell'ultimo blocco"
  },
  "pl": {
    "name": "Nazwa",
    "commission": "Zamawiać",
    "algorithms": "Algorytmy",
    "coins": "Monety",
    "lastblock": "Ostatni blok",
    "lastblockеtime": "Czas ostatniego bloku"
  },
  "zh": {
    "name": "名稱",
    "commission": "委員會",
    "algorithms": "算法",
    "coins": "硬幣",
    "lastblock": "最後一個區塊",
    "lastblockеtime": "最後出塊時間"
  },
  "ja": {
    "name": "名前",
    "commission": "手数料",
    "algorithms": "アルゴリズム",
    "coins": "コイン",
    "lastblock": "最後のブロック",
    "lastblockеtime": "最後のブロック時間"
  }
}
</i18n>
