import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import i18n from '../i18n'
import { additionalLocales } from '../assets/scripts/variables'

const routes = [
  {
    path: '/:locale?/about',
    name: 'About',
    component: () => import('../views/AboutPage')
  }, {
    path: '/:locale?/algorithms/:slug',
    name: 'Algorithm',
    component: () => import('../views/AlgorithmPage')
  }, {
    path: '/:locale?/algorithms',
    name: 'Algorithms',
    component: () => import('../views/AlgorithmsPage')
  }, {
    path: '/:locale?/coins/:slug',
    name: 'Coin',
    component: () => import('../views/CoinPage')
  }, {
    path: '/:locale?/coins',
    name: 'Coins',
    component: () => import('../views/CoinsPage')
  }, {
    path: '/:locale?/policy',
    name: 'Policy',
    component: () => import('../views/PolicyPage')
  }, {
    path: '/:locale?/pools/:slug',
    name: 'Pool',
    component: () => import('../views/PoolPage')
  }, {
    path: '/:locale?/pools',
    name: 'Pools',
    component: () => import('../views/PoolsPage')
  }, {
    path: '/:locale?/rules',
    name: 'Rules',
    component: () => import('../views/RulesPage')
  }, {
    path: '/:locale?',
    name: 'Home',
    component: HomePage
  }, {
    path: '/:pathMatch(.*)*',
    redirect: to => {
      const locale = to.params.pathMatch[0]
      if (additionalLocales.includes(locale)) {
        return {
          name: 'Error',
          params: { locale: locale }
        }
      } else {
        return {
          name: 'Error'
        }
      }
    }
  }, {
    path: '/:locale?/error',
    name: 'Error',
    component: () => import('../views/ErrorPage')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.params.locale === undefined || to.params.locale === '' || to.params.locale === 'en') {
    i18n.locale = 'en'
    if (to.fullPath === '/en') return next({ name: 'Home' })
  } else if (additionalLocales.includes(to.params.locale)) {
    i18n.locale = to.params.locale
  } else {
    return next({ name: 'Error' })
  }
  next()
})

export default router
