export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монеты"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше монет"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coins"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More coins"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Münzen"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr Münzen"])}
      },
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monedas"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más monedas"])}
      },
      "pt": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moedas"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Moedas"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces de monnaie"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de pièces"])}
      },
      "it": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monete"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più monete"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬幣"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多硬幣e"])}
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コイン"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より多くのコイン"])}
      }
    }
  })
}
