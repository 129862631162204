<template lang="pug">
the-header
.wrapper
  router-view
the-footer
</template>

<script>
import '@/assets/styles/tools/app.sass'
import '@/assets/styles/tools/buttons.sass'
import '@/assets/styles/tools/fonts.sass'
import '@/assets/styles/tools/framework.sass'
import '@/assets/styles/tools/inputs.sass'
import '@/assets/styles/tools/links.sass'
import TheHeader from './components/_layout/TheHeader'
import TheFooter from './components/_layout/TheFooter'
import { additionalLocales } from './assets/scripts/variables'

export default {
  name: 'App',
  components: { TheHeader, TheFooter },
  data () {
    return {
      additionalLocales
    }
  },
  watch: {
    '$route.params.locale' (locale) {
      this.$i18n.locale = this.additionalLocales.includes(locale) ? locale : 'en'
    }
  }
}
</script>
