export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алгоритмов"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монет"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний блок"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время последнего блока"])}
      },
      "en": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithms"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сoins"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last block"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last block time"])}
      },
      "de": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithmen"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Münzen"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Block"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Blockzeit"])}
      },
      "es": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmos"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monedas"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["último bloque"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora del último bloque"])}
      },
      "pt": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmos"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moedas"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último bloco"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora do último bloco"])}
      },
      "fr": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithmes"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces de monnaie"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier bloc"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure du dernier bloc"])}
      },
      "it": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmi"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monete"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo blocco"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo dell'ultimo blocco"])}
      },
      "pl": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamawiać"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorytmy"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monety"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni blok"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ostatniego bloku"])}
      },
      "zh": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["委員會"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["算法"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬幣"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後一個區塊"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後出塊時間"])}
      },
      "ja": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料"])},
        "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルゴリズム"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コイン"])},
        "lastblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後のブロック"])},
        "lastblockеtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後のブロック時間"])}
      }
    }
  })
}
