import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueYandexMetrika from 'vue-v3-yandex-metrika'

const app = createApp(App)

app.use(i18n)
app.use(router)
if (!process.env.VUE_APP_METRIKA_NOT_CONNECT) {
  app.use(VueYandexMetrika, {
    id: 87447085,
    router: router,
    env: process.env.NODE_ENV
  })
}

app.mount('#app')
