import req from './request'

const url = process.env.VUE_APP_API

export async function getCoinsForTheHomepage () {
  return await req.post(`${url}coin/list`, { page: 1, limit: 20 })
}

export async function getCoins (page = 1) {
  return await req.post(`${url}coin/list`, { page, limit: 50 })
}

export async function getCoin (slug, pool_page = 1, pool_limit = 20) {
  return await req.post(`${url}coin/item`, { slug, pool_page, limit: pool_limit })
}

export async function getPoolsForTheHomepage () {
  return await req.post(`${url}pool/list`, { page: 1, limit: 20 })
}

export async function getPools (page = 1) {
  return await req.post(`${url}pool/list`, { page, limit: 50 })
}

export async function getPool (slug, coin_page = 1, coin_limit = 20) {
  return await req.post(`${url}pool/item`, { slug, coin_page, limit: coin_limit })
}

export async function getAlgorithms (page = 1) {
  return await req.post(`${url}algorithm/list`, { page, limit: 50 })
}

export async function getAlgorithm (slug, coin_page = 1, coin_limit = 20) {
  return await req.post(`${url}coin/list`, { algorithm_slug: slug, coin_page, limit: coin_limit })
}
