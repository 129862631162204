export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О сайте"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила сервиса"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])}
      },
      "en": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About site"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of service"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])}
      },
      "de": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über die Website"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingungen der Dienstleistung"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbestimmungen"])}
      },
      "es": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre el sitio"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de servicio"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])}
      },
      "pt": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre o site"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras de serviço"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidade"])}
      },
      "fr": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos du site"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles de services"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])}
      },
      "it": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul sito"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole del servizio"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica sulla riservatezza"])}
      },
      "pl": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O stronie"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasady obsługi"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka prywatności"])}
      },
      "zh": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於網站"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務規則"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私政策"])}
      },
      "ja": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイトについて"])},
        "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスルール"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])}
      }
    }
  })
}
