<template lang='pug'>
.info(v-if="info")
  .info__cont.cont
    h2.info__title.title {{ t('title')}}
    .info__table-box
      pools-table(v-if="info.pools", :pools="info.pools" )
    template(v-if="!isPoolsPage")
      router-link.info__button.button(v-if="$i18n.locale === 'en'", :to="{ name: 'Pools'}") {{ t('button')}}
      router-link.info__button.button(v-else, :to="{ name: 'Pools', params: {locale: $i18n.locale}}") {{ t('button')}}
</template>

<script>
import '@/assets/styles/components/info.sass'
import { useI18n } from 'vue-i18n'
import PoolsTable from './PoolsTable'
export default {
  name: 'PoolsInfo',
  components: { PoolsTable },
  props: {
    info: Object
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  data () {
    return {
      headers: [
        { translation: '', sort: false },
        { translation: 'name', sort: true },
        { translation: 'commission', sort: true },
        { translation: 'algorithms', sort: true },
        { translation: 'coins', sort: true }
      ],
      isPoolsPage: false
    }
  },
  mounted () {
    this.isPoolsPage = this.$route.name === 'Pools'
  },
  methods: {
    goToThePoolPage (slug) {
      if (this.$i18n.locale === 'en') {
        this.$router.push({ name: 'Pool', params: { slug } })
      } else {
        this.$router.push({ name: 'Pool', params: { slug, locale: this.$i18n.locale } })
      }
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "title": "Майнинг пулы",
    "button": "Больше монет"
  },
  "en": {
    "title": "Mining pools",
    "button": "More mining pools"
  },
  "de": {
    "title": "Mining-Pools",
    "button": "Mehr Mining-Pools"
  },
  "es": {
    "title": "Piscinas mineras",
    "button": "Más pools de minería"
  },
  "pt": {
    "title": "Piscinas de mineração",
    "button": "Mais pools de mineração"
  },
  "fr": {
    "title": "Piscines minières",
    "button": "Plus de pools de minageo"
  },
  "it": {
    "title": "Piscine minerarie",
    "button": "Più piscine minerarie"
  },
  "pl": {
    "title": "Baseny górnicze",
    "button": "Więcej basenów górniczych"
  },
  "zh": {
    "title": "礦池",
    "button": "更多礦池"
  },
  "ja": {
    "title": "マイニングプール",
    "button": "より多くのマイニングプール"
  }
}
</i18n>
