<template lang='pug'>
.info(v-if="info")
  .info__cont.cont
    h2.info__title.title {{t('title')}}
    .info__table-box
      coins-table(v-if="info.coins", :coins="info.coins" )
    template(v-if="!isCoinsPage")
      router-link.info__button.button(v-if="$i18n.locale === 'en'", :to="{ name: 'Coins' } ") {{t('button')}}
      router-link.info__button.button(v-else, :to="{ name: 'Coins', params: {locale: $i18n.locale} } ") {{t('button')}}
</template>

<script>
import '@/assets/styles/components/info.sass'
import { useI18n } from 'vue-i18n'
import CoinsTable from './CoinsTable'

export default {
  name: 'CoinsInfo',
  components: { CoinsTable },
  props: {
    info: Object
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  data () {
    return {
      headers: [
        { translation: '', sort: false },
        { translation: 'name', sort: true },
        { translation: '', sort: false },
        { translation: 'algorithm', sort: true },
        { translation: 'pools', sort: true }
      ],
      isCoinsPage: false
    }
  },
  mounted () {
    this.isCoinsPage = this.$route.name === 'Coins'
  },
  computed: {
    currentLocale () {
      return this.i18n.locale
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "title": "Монеты",
    "button": "Больше монет"
  },
  "en": {
    "title": "Coins",
    "button": "More coins"
  },
  "de": {
    "title": "Münzen",
    "button": "mehr Münzen"
  },
  "es": {
    "title": "Monedas",
    "button": "Más monedas"
  },
  "pt": {
    "title": "Moedas",
    "button": "Mais Moedas"
  },
  "fr": {
    "title": "Pièces de monnaie",
    "button": "Plus de pièces"
  },
  "it": {
    "title": "Monete",
    "button": "Più monete"
  },
  "zh": {
    "title": "硬幣",
    "button": "更多硬幣e"
  },
  "ja": {
    "title": "コイン",
    "button": "より多くのコイン"
  }
}
</i18n>
