<template lang="pug">
table.data-table.data-table_coins(v-if="coins && coins.length")
  thead.data-table__thead
    tr.data-table__row
      template(v-for="(header, i) in headers", :key="i")
        //th.data-table__head(v-if="header.sort")
        //  button.data-table__head-sort {{header.text}}
        th.data-table__head {{ header.translation ? t(header.translation) : '' }}
  tbody.data-table__body
    tr.data-table__row.data-table__row_hover(v-for="(item, i) in coins", :key="i", @click="goToCoinPage(item.slug)")
      td.data-table__cell.data-table__cell_number {{i + 1}}.
      td.data-table__cell.data-table__cell_coin-name
        .data-table__link
          img.data-table__icon(v-if="item.image", :src="item.image", alt="" )
          span.data-table__text {{ item.name }}
      td.data-table__cell.data-table__cell_abbr
        span.data-table__abbr.text-tiny {{item.symbol}}
      td.data-table__cell.data-table__cell_algorithm {{ item.algorithm }}
      td.data-table__cell.data-table__cell_pools {{item.pool_count}}
      td.data-table__cell {{item.price}}
      td.data-table__cell {{item.emission}}
      td.data-table__cell {{item.pools_hashrate}}
      td.data-table__cell {{item.network_hashrate}}
      td.data-table__cell {{item.volume_24}}
      td.data-table__cell {{item.market_cap}}
      td.data-table__cell {{item.height}}
      //td.data-table__cell.data-table__cell_location
      //  img.data-table__icon(:src="item.locationIcon", alt="" )
</template>

<script>
import '@/assets/styles/components/data-table.sass'
import { useI18n } from 'vue-i18n'

export default {
  name: 'CoinsTable',
  props: {
    coins: Array
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  data () {
    return {
      headers: [
        { translation: '', sort: false },
        { translation: 'name', sort: true },
        { translation: '', sort: false },
        { translation: 'algorithm', sort: true },
        // { text: 'Локация', sort: true }
        { translation: 'pools', sort: true },
        { translation: 'price', sort: true },
        { translation: 'emission', sort: true },
        { translation: 'pools_hashrate', sort: true },
        { translation: 'network_hashrate', sort: true },
        { translation: 'volume_24', sort: true },
        { translation: 'market_cap', sort: true },
        { translation: 'height', sort: true }
      ]
    }
  },
  methods: {
    goToCoinPage (slug) {
      if (this.$i18n.locale === 'en') {
        this.$router.push({ name: 'Coin', params: { slug } })
      } else {
        this.$router.push({ name: 'Coin', params: { slug, locale: this.$i18n.locale } })
      }
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "name": "Название",
    "pools": "Пулов",
    "algorithm": "Алгоритм",
    "price": "Цена",
    "emission": "Эмиссия за последние 24 часа",
    "pools_hashrate": "Хешрейт пулов",
    "network_hashrate": "Хешрейт сети",
    "volume_24": "Объем за 24 часа",
    "market_cap": "Рыночная капитализация",
    "height": "Последний блок (высота)"
  },
  "en": {
    "name": "Name",
    "pools": "Pools",
    "algorithm": "Algorithm",
    "price": "Price",
    "emission": "Emissions in the last 24 hours",
    "pools_hashrate": "Pool hashrate",
    "network_hashrate": "Network hashrate",
    "volume_24": "Volume in 24 hours",
    "market_cap": "Market capitalization",
    "height": "Last block (height)"
  },
  "de": {
    "name": "Name",
    "pools": "Bergbau-Pools",
    "algorithm": "Algorithmus",
    "price": "Preis",
    "emission": "Emissionen in den letzten 24 Stunden",
    "pools_hashrate": "Pool-Hashrate",
    "network_hashrate": "Netzwerk-Hashrate",
    "volume_24": "Volumen in 24 Stunden",
    "market_cap": "Marktkapitalisierung",
    "height": "Letzter Block (Höhe)"
  },
  "es": {
    "name": "Nombre",
    "pools": "Piscinas",
    "algorithm": "Algoritmo",
    "price": "Precio",
    "emission": "Emisiones en las últimas 24 horas",
    "pools_hashrate": "Tasa de hash de la piscina",
    "network_hashrate": "Tasa de hash de red",
    "volume_24": "Volumen en 24 horas",
    "market_cap": "Capitalización de mercado",
    "height": "Último bloque (altura)"
  },
  "pt": {
    "name": "Nome",
    "pools": "Piscinas",
    "algorithm": "Algoritmo",
    "price": "Preço",
    "emission": "Emissões nas últimas 24 horas",
    "pools_hashrate": "Taxa de hash do pool",
    "network_hashrate": "Taxa de hash da rede",
    "volume_24": "Volume em 24 horas",
    "market_cap": "Capitalização de mercado",
    "height": "Último bloco (altura)"
  },
  "fr": {
    "name": "Nome",
    "pools": "Piscines",
    "algorithm": "Algorithme",
    "price": "Prix",
    "emission": "Émissions au cours des dernières 24 heures",
    "pools_hashrate": "Taux de hachage du pool",
    "network_hashrate": "Taux de hachage du réseau",
    "volume_24": "Volume en 24 heures",
    "market_cap": "Capitalisation boursière",
    "height": "Dernier bloc (hauteur)"
  },
  "it": {
    "name": "Nome",
    "pools": "Piscine",
    "algorithm": "Algoritmo",
    "price": "Prezzo",
    "emission": "Emissioni nelle ultime 24 ore",
    "pools_hashrate": "Hashrate in piscina",
    "network_hashrate": "Hashrate di rete",
    "volume_24": "Volume in 24 ore",
    "market_cap": "Capitalizzazione di mercato",
    "height": "Ultimo blocco (altezza)"
  },
  "pl": {
    "name": "Imię",
    "pools": "Totalizator piłkarski",
    "algorithm": "Algorytm",
    "price": "Cena",
    "emission": "Emisje w ciągu ostatnich 24 godzin",
    "pools_hashrate": "Hashrate w basenie",
    "network_hashrate": "Hashrate sieci",
    "volume_24": "Głośność w ciągu 24 godzin",
    "market_cap": "Kapitalizacja rynkowa",
    "height": "Ostatni blok (wysokość)"
  },
  "zh": {
    "name": "名稱",
    "pools": "游泳池",
    "algorithm": "算法",
    "price": "價錢",
    "emission": "過去 24 小時內的排放量",
    "pools_hashrate": "礦池算力",
    "network_hashrate": "網絡算力",
    "volume_24": "24 小時內的交易量",
    "market_cap": "市值",
    "height": "最後一塊（高度）"
  },
  "ja": {
    "name": "名前",
    "pools": "プール",
    "algorithm": "アルゴリズム",
    "price": "価格",
    "emission": "過去24時間の排出量",
    "pools_hashrate": "プールハッシュレート",
    "network_hashrate": "ネットワークハッシュレート",
    "volume_24": "24時間でボリューム",
    "market_cap": "時価総額",
    "height": "最後のブロック（高さ）"
  }
}
</i18n>
